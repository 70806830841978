ol {
	margin: 0;
	padding: 0
  }

  table td,
  table th {
	padding: 0
  }

  .c3 {
	color: #000000;
	font-weight: 400;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 11pt;
	font-family: "Arial";
	font-style: normal
  }

  .c2 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: center;
	height: 11pt
  }

  .c0 {
	color: #000000;
	font-weight: 400;
	text-decoration: none;
	vertical-align: baseline;
	font-size: 11pt;
	font-family: "Arial";
	font-style: italic
  }

  .c5 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: left;
	height: 11pt
  }

  .c1 {
	padding-top: 0pt;
	text-indent: 36pt;
	padding-bottom: 0pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: left
  }

  .c4 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: center
  }

  .c8 {
	padding-top: 0pt;
	padding-bottom: 0pt;
	line-height: 1.15;
	orphans: 2;
	widows: 2;
	text-align: left
  }

  .c9 {
	background-color: #ffffff;
	max-width: 468pt;
	padding: 72pt 72pt 72pt 72pt
  }

  .c7 {
	text-indent: 36pt
  }

  .c6 {
	font-style: italic
  }

  .title {
	padding-top: 0pt;
	color: #000000;
	font-size: 26pt;
	padding-bottom: 3pt;
	font-family: "Arial";
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left
  }

  .subtitle {
	padding-top: 0pt;
	color: #666666;
	font-size: 15pt;
	padding-bottom: 16pt;
	font-family: "Arial";
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left
  }

  li {
	color: #000000;
	font-size: 11pt;
	font-family: "Arial"
  }

  p {
	margin: 0;
	color: #000000;
	font-size: 11pt;
	font-family: "Arial"
  }

  h1 {
	padding-top: 20pt;
	color: #000000;
	font-size: 20pt;
	padding-bottom: 6pt;
	font-family: "Arial";
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left
  }

  h2 {
	padding-top: 18pt;
	color: #000000;
	font-size: 16pt;
	padding-bottom: 6pt;
	font-family: "Arial";
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left
  }

  h3 {
	padding-top: 16pt;
	color: #434343;
	font-size: 14pt;
	padding-bottom: 4pt;
	font-family: "Arial";
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left
  }

  h4 {
	padding-top: 14pt;
	color: #666666;
	font-size: 12pt;
	padding-bottom: 4pt;
	font-family: "Arial";
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left
  }

  h5 {
	padding-top: 12pt;
	color: #666666;
	font-size: 11pt;
	padding-bottom: 4pt;
	font-family: "Arial";
	line-height: 1.15;
	page-break-after: avoid;
	orphans: 2;
	widows: 2;
	text-align: left
  }

  h6 {
	padding-top: 12pt;
	color: #666666;
	font-size: 11pt;
	padding-bottom: 4pt;
	font-family: "Arial";
	line-height: 1.15;
	page-break-after: avoid;
	font-style: italic;
	orphans: 2;
	widows: 2;
	text-align: left
  }