#root {
    
}

.main-app-container {
}

#app {
    
    opacity: 0;
    
}

#background-image {

}

@media screen and (max-width: 640px) {
    .large-container {
        display: flex;
        flex-direction: column;
        width: fit-content;
        
    }
}
    

/**
#background-image {
    width: inherit;
    position:absolute;
    justify-content: right;
    
    top: 30%;
    left: 10%;
    right: 20%;
    bottom: 0%;
    opacity: 50%;
    z-index: -1;
}
*/