.terminal {
    margin-bottom: 0px;    
    height: 100%;
    width: 100%;
    position: fixed;
    
    overflow: scroll;
    background-color: #000000;
    color: #ffffff;
    padding: 35px 45px;
    font-size: 14px;
    line-height: 1.42;
    font-family: 'IBM Plex Mono', Consolas, Menlo, Monaco, 'Courier New', Courier,
    monospace;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    /* left: 200px; */
    z-index: 1;
    bottom: -50px;
    right: -80px;

    --code-text-color : rgb(206, 59, 59);
    --code-highlight-color: rgba(180, 180, 180, 0.541);
}

.terminal__line {
    line-height: 2;
    white-space: pre-wrap;
}

.terminal__prompt {
    display: flex;
    align-items: center;
}

.terminal__prompt__label {
    flex: 0 0 auto;
    color: #F9EF00;
    
}

.terminal__prompt__input {
    flex: 1;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    color: white;
}

.terminal__prompt__input input {
    flex: 1;
    width: 100%;
    background-color: transparent;
    color: white;
    border: 0;
    outline: none;
    font-size: 14px;
    line-height: 1.42;
    font-family: 'IBM Plex Mono', Consolas, Menlo, Monaco, 'Courier New', Courier,
    monospace;
}

.terminal__prompt__input input::placeholder {
    color: #ffffff;
}

.error-header {
    color: #f90000;
    font-weight: 600;
}

.terminal__link {
    color: #ffffff;
    text-decoration: none;
}

.memory-buffer-line>span:hover {
    
    color: black;
    background-color: white;
    cursor: pointer;
}

.memory-buffer-line>span::after {
    content: ' ';

}


.help {
    
}

.help>.heading {

}

.help>.line {

}

.standard.emphasized {

}

.terminal-text {
    color: white;
}

.standard.codereference {
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 3px;
    color: rgb(255 255 255);
    background-color: rgb(255 0 0 / 54%);
}

.help>.subheading {
    
}

.command-highlight {
    font-weight: 600;
    color: rgb(27, 245, 245);
}

.type-highlight {
    color: rgb(255, 166, 1);
}

