:root {
    --primary_red:#E9165F;
    --primary_yellow:#E9DA16;
    --primary_blue:#165FE9;
    --primary_neon_green: #5FE916;
    /**
    background-color: var(--primary_yellow);
    */
    
}

.color-primary-blue {
    color: var(--primary_blue);
}

.color-primary-red {
    color: var(--primary_red);
}

.color-primary-yellow {
    color: var(--primary_yellow);
}

.background-color-red {
    background-color: var(--primary_red);
}

.background-color-yellow {
    background-color: var(--primary_yellow);
}

.white {
    color: white;
}

.black {
    color: black;
}

body {
    background-color: #f7f8fc;
}

* {
    /* font-family: 'Noto Sans JP' !important; */
    font-family: 'Heebo', sans-serif !important;
}

.font-hacked {
    font-family: 'hackedregular' !important;
}

.font-krishna {
    font-family: 'krisharegular' !important;
}

.font-swiss {
    font-family: 'swis721_blkcn_btblack' !important;
}

.font-consolas {
    font-family: 'consolasregular' !important;
}

/**
    GLITCH ANIMATION
*/







/**
.background-video {
    position: absolute;
    display: block !important;
    z-index: -2;
    margin: 0;
    left: 0;
    top: 0;
    width: inherit;
    bottom: 50px;
    opacity: 80%;
    overflow: hidden;
}
*/