@media screen and (max-width: 1300px) {
    .newsbar {
        display: flex;
        width: 90vw;
        margin-left: 0 !important;
        align-self: center;
        justify-self: flex-start;
    }
}
    

.newsbar {
    background-color: var(--primary_blue) !important;
    border: 1px solid rgba(255, 255, 255, 0.815);
    margin-left: 2%;
    
}

.newsbar>.MuiGrid-item >.font_hacked{
    color: var(--primary_red);
}

.newsbar>h3 {
    color: var(white);

}

.makeStyles-newsBarTitle-24 {
    color: white;
}

.newsbar-post-body {
    color: white;
    font-size: small !important; 
    margin-bottom: 0px !important;
}

.newsbar-post-subtitle {
    color: white;
}

.newsbar-post-title {
    color: white !important;
    padding-top: 7px !important;
}

.news-post-card {
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: black !important;
    
    padding-top: 0px;
}

.news-post-card>.MuiCardContent-root {
    padding-bottom: 4px !important;
    padding-top: 0px !important;
    
}

